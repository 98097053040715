import { useRef, useState } from "react";
import { useToast, Button, ScrollArea, Input } from "@withjuly/solisv2";
import { useZodForm } from "~/utils/hooks/zod-form";
import { trpc } from "../Utility/trpc";
import { ArrowsCounterClockwise, Plus, Upload } from "@withjuly/julycons/bold";
import { z } from "zod";
import { useAgency } from "~/utils/context/agency";
import * as Dialog from "@radix-ui/react-dialog";
import { motion } from "framer-motion";

type SetUpStep = "logo" | "inviteManagers";
export const AccountSetUpModal = ({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}) => {
	const { agencyProfile } = useAgency();
	const { toast } = useToast();

	// STATE
	const [isUploading, setIsUploading] = useState(false);
	const [image, setImage] = useState<string | null>(
		() => agencyProfile?.logoUrl ?? null,
	);
	const [progress, setProgress] = useState<number>(5);
	const [step, setStep] = useState<SetUpStep>("logo");
	const [email, setEmail] = useState("");
	const [emailList, setEmailList] = useState<string[]>([]);
	const [emailError, setEmailError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// HELPERS
	const getUploadUrl = trpc.agency.getLogoUploadUrl.useMutation();
	const uploadProfilePicture = trpc.agency.uploadProfilePicture.useMutation();
	const form = useZodForm({
		schema: z.object({ uuid: z.string().uuid() }),
		values: agencyProfile?.logo?.uuid
			? {
					uuid: agencyProfile.logo.uuid,
				}
			: undefined,
		submit: async (data, { onFormError }) => {
			uploadProfilePicture.mutate(
				{
					uuid: data.uuid,
				},
				{
					onError: (error: unknown) => {
						onFormError(error);
					},
				},
			);
		},
		mode: "onBlur",
	});
	const fields = form.watch();
	const uploadRef = useRef<HTMLInputElement | null>(null);

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsUploading(true);
		const file = e.target.files?.[0];

		if (file) {
			if (file.size > 10_000_000) {
				toast({
					title: "That file is too large",
					description: "Please upload a file less than 10MB",
				});
				e.target.value = "";
				setIsUploading(false);
				return;
			}

			const fileReader = new FileReader();
			fileReader.addEventListener(
				"load",
				() => {
					setImage(() =>
						fileReader.result ? fileReader.result.toString() : null,
					);
				},
				false,
			);
			fileReader.readAsDataURL(file);

			const { url, fields, upload } = await getUploadUrl.mutateAsync({
				fileName: file.name,
				fileType: file.type,
				size: file.size,
			});

			const formData = new FormData();
			Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			await fetch(url, {
				method: "POST",
				body: formData,
			});

			form.setValue("uuid", upload.uuid);
		}

		setIsUploading(false);
		setProgress(50);
	};

	const utils = trpc.useContext();
	const invite = trpc.agency.inviteMembers.useMutation({
		onSuccess: () => utils.agency.getMembers.invalidate(),
		onError: () => {
			toast({
				title: "Could not invite new agency member",
				description:
					"Please verify that this email isn't already in use and try again",
				variant: "error",
			});
		},
	});
	const completedAgencySetUp = trpc.agency.completedAgencySetUp.useMutation();

	const onFinish = () => {
		setIsLoading(true);
		invite.mutate(emailList, {
			onSuccess: () => {
				completedAgencySetUp.mutate(undefined, {
					onSuccess: () => {
						utils.agency.get.invalidate();
						setIsLoading(false);
						setIsOpen(false);
					},
					onError: () => {
						setIsLoading(false);
					},
				});
			},
			onError: () => {
				setIsLoading(false);
			},
		});
	};

	return (
		<Dialog.Root modal={false} open={isOpen} onOpenChange={setIsOpen}>
			<Dialog.Portal>
				<div className="font-repro bg-sky-1/75 pointer-events-auto fixed inset-0 z-40 h-screen w-screen data-[state=closed]:animate-[fade-out_0.10s_ease-in-out_forwards] data-[state=open]:animate-[fade-in-no-scale_250ms_ease-in-out]" />
				<Dialog.Content
					className="group fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform rounded-[20px]"
					onInteractOutside={(e) => e.preventDefault()}
					onEscapeKeyDown={(e) => e.preventDefault()}
				>
					<motion.div
						data-size="lg"
						initial={{
							width: "800px",
							scale: 0.5,
						}}
						transition={{
							duration: 0.15,
							type: "just",
						}}
						exit={{
							scale: 0.5,
						}}
						animate={{
							width: "800px",
							scale: 1.0,
						}}
						className="bg-surface-primary border-stroke-primary overflow-clip rounded-[20px] border"
					>
						<div className="font-repro border-b-stroke-tertiary relative flex w-full items-start justify-between px-8 py-6 first:border-b">
							<div className="flex w-full flex-col items-start gap-2">
								<p className="group-data-[size=sm]:text-header-lg text-header-xl">
									{step === "logo" ? "Add your logo" : "Invite your team"}
								</p>
								<p className="text-paragraph-sm h-fit opacity-[64%]">
									{step === "logo"
										? "This will be shown on your assets (Media Kits, Roster, Decks etc.)"
										: "Managers at your agency can use July to create assets, track growth and more."}
								</p>
							</div>
						</div>
						<div className="bg-surface-quaternary h-1 w-full">
							<div
								className="bg-brand h-1 transition-[width] duration-500 ease-in-out"
								style={{
									width: `${progress}%`,
								}}
							>
								{/** PROGRESS BAR */}
							</div>
						</div>
						{step === "logo" ? (
							<ScrollArea className="rounded-inherit h-full max-h-[60vh] w-full px-8 py-6">
								<div className="flex flex-col items-center gap-6 py-10">
									<button
										className="bg-surface-hover-1 flex h-24 w-24 items-center justify-center rounded-full"
										style={{
											backgroundImage: image ? `url(${image})` : undefined,
											backgroundSize: "cover",
											backgroundPosition: "center",
										}}
										onClick={() => uploadRef.current?.click()}
									>
										{form.watch("uuid") ? null : <Plus className="h-5 w-5" />}
									</button>

									<Button
										isLoading={isUploading}
										onClick={() => uploadRef.current?.click()}
										leadingIcon={
											fields.uuid && fields.uuid !== ""
												? ArrowsCounterClockwise
												: Upload
										}
										variant={
											fields.uuid && fields.uuid !== "" ? "blank" : "secondary"
										}
									>
										{fields.uuid && fields.uuid !== ""
											? "Replace"
											: "Upload image"}
									</Button>
									<input
										className="hidden"
										type="file"
										ref={uploadRef}
										onChange={onUpload}
									/>
								</div>
							</ScrollArea>
						) : null}

						{step === "inviteManagers" ? (
							<div className="flex flex-col gap-4 p-8">
								<div className="flex w-full items-start gap-4">
									<Input
										placeholder="Enter Email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										label="Manager email"
										autofocus
										error={emailError}
										errorMessage="Invalid email"
									/>
									<Button
										className="mt-6 whitespace-nowrap align-top"
										variant="secondary"
										onClick={(e) => {
											e.preventDefault();
											if (!z.string().email().safeParse(email).success) {
												setEmailError(true);
											} else if (emailList.includes(email)) {
												toast({
													title: "Email already added",
												});
											} else {
												setEmailList([...emailList, email]);
												setEmail("");
												setEmailError(false);
											}
										}}
									>
										Invite
									</Button>
								</div>

								<div className="border-stroke-secondary flex h-[287px] w-full flex-col rounded-lg border">
									<ScrollArea className="h-[287px]">
										{emailList.map((email) => (
											<>
												<div
													key={email}
													className="flex min-h-[65px] items-center justify-between pl-6 pr-4"
												>
													<p className="text-button-sm">{email}</p>
													<Button
														variant="outline"
														onClick={(e) => {
															e.preventDefault();
															const newEmailList = [...emailList].filter(
																(e) => e !== email,
															);
															setEmailList(newEmailList);
														}}
													>
														Remove
													</Button>
												</div>
												<div
													key={`${email}-divider`}
													className="bg-stroke-tertiary h-px w-full last:hidden"
												/>
											</>
										))}
									</ScrollArea>
								</div>
							</div>
						) : null}
						<div className="border-t-stroke-tertiary flex w-full justify-end gap-2 border-t px-8 py-6">
							<Button
								onClick={(e) => {
									e.preventDefault();
									if (step === "logo") {
										form.onSubmit();
										setProgress(100);
										setStep("inviteManagers");
									} else if (step === "inviteManagers") {
										onFinish();
									}
								}}
								disabled={!fields.uuid || fields.uuid === ""}
								isLoading={isLoading}
							>
								{step === "logo" ? "Continue" : "Finish"}
							</Button>
						</div>
					</motion.div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
