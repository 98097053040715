import { Root, Item } from "@radix-ui/react-toggle-group";
import { cx } from "@withjuly/frontend-common";
import { Check } from "@withjuly/julycons/bold";
import React, { useRef } from "react";

export interface ToggleGroupSelectProps {
	className?: string;
	disabled?: boolean;
	value: string[];
	setValue: (value: string[]) => void;
	children: React.ReactNode;
	center?: boolean;
	style?: React.CSSProperties;
}

export const ToggleGroupSelect = React.forwardRef<
	React.ElementRef<typeof Root>,
	ToggleGroupSelectProps
>(({ className, children, disabled, setValue, value, center, style }, ref) => {
	return (
		<Root
			type="multiple"
			disabled={disabled}
			onValueChange={setValue}
			value={value}
			className={cx("flex flex-wrap", center && "justify-center", className)}
			style={style}
			ref={ref}
		>
			{children}
		</Root>
	);
});

export interface ToggleGroupSelectItemProps {
	children: React.ReactNode;
	value: string;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export const ToggleGroupSelectItem: React.FC<ToggleGroupSelectItemProps> = ({
	children,
	value,
	disabled,
	className,
	style,
}) => {
	const itemRef = useRef<HTMLButtonElement | null>(null);

	return (
		<Item
			disabled={disabled}
			value={value}
			className={cx(
				"border-stroke-tertiary group border-b px-8 py-6 transition-colors",
				"data-[state=off]:bg-surface-primary",
				"data-[state=on]:bg-sky-alpha-4",
				className,
			)}
			style={style}
			ref={itemRef}
		>
			<div
				className={cx(
					"border-stroke-primary rounded-solis-sm flex h-4 w-4 items-center justify-center border transition-colors",
					"group-data-[state=on]:border-brand group-data-[state=on]:bg-brand",
				)}
			>
				<Check color="#0E1316" width="10px" height="10px" />
			</div>

			{children}
		</Item>
	);
};

ToggleGroupSelect.displayName = "ToggleGroupSelect";
