import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import { MagnifyingGlass } from "@withjuly/julycons/bold";
import { Input, useToast } from "@withjuly/solisv2";
import { useEffect, useRef, useState } from "react";

interface LocationPickerProps {
	setSelectedPlace: (
		locality: string | null,
		region: string | null,
		country: string | null,
	) => void;
	label?: string;
	value?: string;
}

export const LocationPicker = ({
	setSelectedPlace,
	label,
	value,
}: LocationPickerProps) => {
	const { toast } = useToast();

	const getGoogleApiKey = () => {
		const apiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
		if (!apiKey) {
			toast({
				title: "Something went wrong.",
				description: "If this issue persists, please contact support",
				variant: "error",
			});
		}
		return apiKey || "";
	};

	return (
		<APIProvider apiKey={getGoogleApiKey()}>
			<div className="autocomplete-control">
				<PlaceAutocomplete
					key={value}
					onPlaceSelect={setSelectedPlace}
					label={label}
					value={value}
				/>
			</div>
		</APIProvider>
	);
};

interface PlaceAutocompleteProps {
	onPlaceSelect: (
		locality: string | null,
		region: string | null,
		country: string | null,
	) => void;
	label?: string;
	value?: string;
}

const PlaceAutocomplete = ({
	onPlaceSelect,
	label,
	value,
}: PlaceAutocompleteProps) => {
	const [placeAutocomplete, setPlaceAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const places = useMapsLibrary("places");
	const [search, setSearch] = useState(value);

	useEffect(() => {
		if (!places || !inputRef.current) return;

		const options = { types: ["(regions)"] };

		const autocompleteInstance = new places.Autocomplete(
			inputRef.current,
			options,
		);
		setPlaceAutocomplete(autocompleteInstance);
	}, [places]);

	useEffect(() => {
		if (!placeAutocomplete) return;

		placeAutocomplete.addListener("place_changed", () => {
			const place = placeAutocomplete.getPlace();
			if (!place || !place.types) return;

			const allowedTypes = [
				"locality",
				"administrative_area_level_1",
				"country",
			];
			const isValidPlace = place.types.some((type) =>
				allowedTypes.includes(type),
			);

			const locality: google.maps.GeocoderAddressComponent | undefined = (
				place.address_components ?? []
			).find((component) => component.types.includes("locality"));

			const region: google.maps.GeocoderAddressComponent | undefined = (
				place.address_components ?? []
			).find((component) =>
				component.types.includes("administrative_area_level_1"),
			);

			const country: google.maps.GeocoderAddressComponent | undefined = (
				place.address_components ?? []
			).find((component) => component.types.includes("country"));

			if (isValidPlace) {
				// Override USA short name
				if (country?.short_name === "US") {
					country.short_name = "USA";
				}

				let formattedPlace = locality?.long_name;

				if (country && !region && !locality) {
					formattedPlace = country.long_name;
				} else if (country && region && !locality) {
					formattedPlace = `${region.long_name}, ${country.short_name}`;
				} else if (country && region && locality) {
					formattedPlace = `${locality.long_name}, ${region.short_name}, ${country.short_name}`;
				}
				setSearch(formattedPlace);

				const formattedLocality = locality?.long_name;
				const formattedRegion = locality
					? region?.short_name
					: region?.long_name;
				const formattedCountry = region
					? country?.short_name
					: country?.long_name;
				onPlaceSelect(
					formattedLocality ?? null,
					formattedRegion ?? null,
					formattedCountry ?? null,
				);
			}
		});
	}, [onPlaceSelect, placeAutocomplete]);

	return (
		<div className="autocomplete-container">
			<Input
				ref={inputRef}
				label={label}
				placeholder="Search location"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				leadingIcon={MagnifyingGlass}
			/>
		</div>
	);
};
